<template>
  <div class="smart-farm">
    <!-- 导航栏 -->
    <Nav :isHome="false"></Nav>
    <CommonNav pageName="社享家—电商社区服务平台"></CommonNav>
    <section>
      <img class="smart-farm-bg" src="../../assets/images/sxj/bg.png" alt="">
    </section>
    <section class="smart-farm-container">
      <!-- <p class="smart-farm-head">与哈尔滨工业大学人工智能研究院及东北林业大学人工智能实验室，针对数字农业项目开展产学研合作，共同针对数字农业模型、粮食烘干系统等进行针对性的研发，旨在重点解决农产品在产量及质量上的关键性问题。</p> -->
      <h4>社享家简介</h4>
      <p class="smart-farm-article">社享家作为四福科技有限公司全资子公司主要负责公司整体的运营工作，其中包含了平台电商运营、社区电商运营、城市电商运营等，社享家意在整合线上线下资源打造电商生态。</p>
      <h4>社享家权益平台</h4>
      <p class="smart-farm-article">作为黑龙江省三代社保卡开卡推广的官方合作单位，为了黑龙江人民的衣食住行与使用，哈尔滨四福科技有限公司开发一款面向龙江人民的生活服务类
        APP—社享家。黑龙江第三代社保卡用户可在 APP 内分享、参与各项优惠活动，享受生活、工作、娱乐、消费体验。</p>
      <p class="smart-farm-article">社享家旨在打造以生活圈、工作区为基础的大型社交权益服务平台。让龙江百姓快速了解周围的综合权益资讯，实现生活、工作、娱乐、休闲、消费一卡通。</p>
    </section>
    <section>
      <div :class="['sxj-project-list', innerWidth * 1 > 1920 ? 'sxj-project-list-big' : '']">
        <div class="sxj-project-content">
          <h5 class="sxj-project-content-title">社区团购小程序</h5>
          <p>
            社区团购小程序是以生活社区为核心辐射周边商家并结合社区群内运营“社区团购”项目，团购内容丰富例“水果团购”“生活团购”“家电团购”等。平台选取优质供应链做到价格优势，依商家门店作为中转以及仓储服务做到仓储优势，并在每个社区内开拓团长为小区团购业务提供服务做到服务优势。
          </p>
        </div>
        <img src="../../assets/images/sxj/imgFirst.png" alt="">
      </div>
      <div :class="['sxj-project-list', innerWidth * 1 > 1920 ? 'sxj-project-list-big' : '']">
        <div class="sxj-project-content">
          <h5 class="sxj-project-content-title">高校外卖小程序</h5>
          <p>
            校园外卖作为外卖的垂直分支领域，与平时的美团、饿了么不同的是，校园外卖需要搭建校内骑手团队，完成“最后一公里”的配送，社享家外卖小程序整合了高校食堂及校内骑手团队，并完成了校内宣传渠道的搭建形成了高校外卖的整体闭环，同时社享家高校外卖小程序也是社享家撬动高校市场的第一步。
          </p>
        </div>
        <img src="../../assets/images/sxj/imgSecond.png" alt="">
      </div>
      <div :class="['sxj-project-list', 'sxj-project-lastlist', innerWidth * 1 > 1920 ? 'sxj-project-list-big' : '']">
        <div class="sxj-project-content">
          <h5 class="sxj-project-content-title">社享呗社区小程序</h5>
          <p>
            社享呗小程序作为社享家核型功能意在打造专属信用积分体系，用户可以实现在社享家各个应用的使用场景中通过返还、领取、兑换等方式获取“社享呗”，商家也可以在用户消费“社享呗”后获取等额的“社享呗”，同时无论是商家或是用户也可以在社享家的多个消费场景中实现使用“社享呗”消费，为了用户及商家可以更加安全、放心的去使用社享家将融入区块链、金融机构等来保障“社享呗”的安全和合理性，作为民生消费场景“社享呗”想实现的是同更多的合作伙伴来共建美好生活社区。
          </p>
        </div>
        <img src="../../assets/images/sxj/imgThird.png" alt="">
      </div>
    </section>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
import CommonNav from '@/components/commonNav.vue'
import Footer from '@/components/footer.vue'

export default {
  data() {
    return {
      innerWidth: null
    }
  },
  components: {
    Nav,
    CommonNav,
    Footer
  },
  created() {
    this.innerWidth = window.innerWidth
  },
  beforeDestroy() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.smart-farm {
  width: 100vw;
  padding-top: 120px;
}

.smart-farm-bg {
  width: 100%;
  height: 100%;
}

.smart-farm-container {
  width: 55%;
  max-width: 1057px;
  margin: 106px auto 126px;
  font-size: 22px;
  color: #808080;
  line-height: 2;
  text-align: left;

  // .smart-farm-head{
  //   margin-bottom: 20px;
  // }
  h4 {
    height: 45px;
    font-size: 30px;
    color: #202020;
    font-weight: 500;
    padding-left: 10px;
    border-left: 3px solid #C7020A;
    margin-top: 30px;
    line-height: 45px;
  }

  .smart-farm-article {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }

  .smart-farm-title {
    color: #C7050F;
    margin-bottom: 20px;
    font-weight: 500;
  }

  h5 {
    font-weight: 400;
    font-size: 22px;
  }
}

.sxj-project-list {
  width: 72.6%;
  max-width: 1395px;
  margin-left: 23%;
  display: flex;
  align-items: center;
  margin-bottom: 36px;

  .sxj-project-content {
    margin-right: 46px;
    flex: 1;
    font-size: 22px;
    color: #808080;
    text-align: left;
    line-height: 2.1;

    h5 {
      height: 45px;
      color: #202020;
      font-weight: 500;
      margin-bottom: 20px;
      font-size: 30px;
      padding-left: 10px;
      border-left: 3px solid #C7020A;
      line-height: 45px;
    }
  }

  img {
    width: 33.2%;
    height: 100%;
  }

  p {
    text-align: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }
}

.sxj-project-list-big {
  margin-left: 29%;
}

.sxj-project-lastlist {
  margin-bottom: 154px;
}
</style>